
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-input--radio-group::v-deep legend {
  color: $color-primary;
  font-weight: 700;
  font-size: 16px;
}

.v-radio::v-deep .v-label {
  min-height: 8px;
  font-size: 16px;
  color: $color-primary !important;
  height: 20px;
  line-height: 20px;
}
