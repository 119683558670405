
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import './scss/ads.scss';
@import '@/scss/app.scss';

.mobile {
  .v-toolbar__content {
    display: none;
  }
  .theme--dark.v-app-bar.v-toolbar.v-sheet {
    height: 30px !important;
  }
  .v-main {
    padding-top: 50px !important;
  }
}

.session-countdown {
  margin-top: 6rem;
  margin-bottom: -3rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.session-timeout {
  top: 4rem;
}
