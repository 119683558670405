
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.school-switcher {
  min-width: 20rem;
  &--single ::v-deep input {
    color: $color-white;
  }
}
