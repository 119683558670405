
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.sort-order-toggle {
  transition: rotate 0.5s;
  border: 2px solid transparent;

  &:focus {
    border: 2px solid currentColor;
  }

  &::before {
    content: none !important;
  }
  &--descending {
    .sort-order-toggle__icon {
      transform: rotate(180deg);
    }
  }
}
