
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-settings__header {
  background: $ads-navy;
  color: white !important;
  position: sticky; // sticky allows keyboard scroll when focused in the header
  top: 0;
  left: 0;
  width: 100vw;
  height: 64px;
  z-index: 6;
  h1 {
    font-size: 1.25rem;
  }
  .v-btn {
    &:focus {
      border: 2px solid $color-focus !important;
    }
  }
}

.app-settings__content {
  color: $ads-navy;
  background: $ads-light-10;
  width: 100%;
  min-height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 0;
  }
  p.subtitle {
    font-size: 16px;
    color: $ads-navy;
  }
  .app-settings__circle-icon {
    border-radius: 50%;
    background: $ads-light-blue;
    color: $ads-navy;
    min-width: 54px;
    height: 54px;
    &.large {
      min-width: 116px;
      height: 116px;
    }
  }
  .app-settings__last-updated {
    font-style: italic;
    color: $ads-dark-70;
    font-size: 14px;
    .v-icon {
      color: $ads-dark-70;
    }
  }
  .app-settings-heading {
    font-size: 20px;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__subheading {
    font-size: 16px;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__paragraph-text {
    max-width: 480px;
  }
  .full-width {
    width: 100%;
    height: 39px;
    button {
      float: right;
    }
  }
  .sr-only {
    background-color: transparent;
  }
  .v-tabs {
    .v-tab {
      font-size: 18px;
      margin-bottom: 20px;
      letter-spacing: normal;
      padding-left: 60px;
      width: 292px;
      height: 67px;

      span {
        z-index: 1;
      }
    }
    .v-tabs-bar,
    .v-tabs-items {
      background: $ads-light-10;
    }
    .v-tab--active {
      background: $ads-white;
    }
  }
}

.app-settings__header-save-button {
  background-color: $color-tertiary !important;
  border-color: $color-tertiary !important;
}
