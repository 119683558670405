
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-alert.error {
  background-color: $color-white !important;
  color: $grey-dark !important;
  border-left: 8px solid $ads-error-red !important;
}

.v-alert.error .v-icon {
  color: $ads-error-red;
}
