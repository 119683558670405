.container {
    padding: 0;
}
button,
input[type="button"] {

  &.unstyled {
    // Removes all button styling, for when a button needs to
    // look like a plain element.
    padding: 0;
    margin: 0;
    color: inherit;
    background-color: transparent;
    font-weight: inherit;
    border-radius: 0;
    border: 0;
    text-align: left;
  }
}

button.v-btn.btn-link.v-btn--text {
  color: $ads-navy;
  height: auto;
  padding: 0;
  font-size: inherit;
  min-width: 0;
  text-decoration: underline;
  font-weight: bold;
  outline: none;
}



//vuetify overrides

.v-application--is-ltr {
    .v-list-item__icon:first-child{
        margin-right: 16px;
    }
}

.v-list-item__icon{
    margin: 10px;
}

.v-application .subtitle-1 {
  font-weight: 700;
  font-size: 1.25rem !important;
  line-height: 25px;
  color: $color-primary;
}

.dataFields {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
}

.header {
  font-weight: bold;
  font-size: 16px;
  color: $color-primary;
  overflow-wrap: break-word;
  margin: 2rem;
  padding-top: 10px;
}

.subHeader {
  border-bottom: 0px solid $ads-light-20;
  font-weight: bold;
  font-size: 16px;
  color: $color-primary;
  overflow-wrap: break-word;
  margin-left: 2rem !important;
  margin-bottom: 1rem !important;
}

.contactSectionHeader {
  border-bottom: 0px solid $ads-light-20;
  font-weight: bold;
  font-size: 16px;
  color: $color-primary;
  overflow-wrap: break-word;
  margin-left: 2rem !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.contactFields {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
  padding-top: 2px;
}

.v-expansion-panel-content__wrap .v-text-field input, .v-expansion-panel-content__wrap .v-text-field .v-label {
  margin-left: 2rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.v-expansion-panel-content__wrap .v-text-field input, .v-expansion-panel-content__wrap .v-text-field__slot textarea {
  margin: 0 1rem 0 2rem;
}

.displayField {
  margin-left: 2.9rem;

  label {
    font-size: 14px;
  }
}

// override new vuetify vertical neg padding
.row {
  margin-top: unset;
  margin-bottom: unset;
}

.row + .row {
  margin-top: unset;
}