
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-dialog > .v-card > .v-card__title.modal__heading {
  font-weight: 700;
  font-size: 1.25rem;
  color: $color-primary;
  padding-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
}

.v-dialog > .v-card > .v-card__text.modal__text {
  color: $grey-dark;
  font-size: 1rem;
}
.modal {
  &__close-button {
    align-self: flex-start;
    margin: -1.5rem -1rem 0 auto;
  }

  &__title {
    max-width: calc(100% - 28px);
    word-break: break-word;
  }

  &__actions {
    justify-content: flex-end;

    ::v-deep button.v-btn {
      height: 3rem;
      padding: 0 2rem;
      max-width: 100%;
    }
  }
}
.v-dialog > .v-card > .modal__actions {
  padding: 1rem 1.5rem 2rem;
}
