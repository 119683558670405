
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.divider {
  border-top: 0;
}

.checklist__question {
  margin-top: 1rem;

  ::v-deep .v-label {
    color: $color-primary;
  }
}

.planning-card {
  &__title {
    font-size: 1rem;
  }
}

.flag-note {
  display: flex;
  width: 38rem;

  &__icon {
    margin-right: 1rem;
  }

  &__text {
    margin-bottom: 0;
  }
}

.flag-special {
  display: flex;
  width: 38rem;

  &__icon {
    margin-right: 1rem;
    color: $ads-warning-orange;
  }

  &__text {
    margin-bottom: 0;
  }
}

.error-message.error-message--alert {
  &,
  ::v-deep * {
    color: $color-primary !important;
  }
}

.checklist {
  box-shadow: none !important;
}
