
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.studentShadowBox {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 2rem;
}
::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
