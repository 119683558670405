
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.banner {
  margin-top: 0px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;
}

.banner__title {
  font-size: 2.125rem;
  line-height: 1;
  text-align: left;
  padding-left: 0px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.banner__text {
  font-size: 1rem;
  line-height: 40px;
}

.banner__icon {
  margin-top: -0.5rem;
}

.filter-label {
  color: $color-placeholder;
}

.certification-list::v-deep {
  .dv-search {
    width: 100%;
  }

  line-height: 3.5rem;
  @media (max-width: 1250px) {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  &.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: 0px;
  }
  &.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    color: $color-primary;
    font-weight: normal;
    font-size: 1rem;
  }
  &.theme--light.v-data-table .v-data-table-header th.sortable.active {
    color: $color-primary;
    font-weight: bold;
    font-size: 1rem;
  }
}

#table-container
  > div.v-data-table.dataTable.certification-list.theme--light
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr
  > td
  > div.scholasticYear {
  background-color: $ads-tertiary-blue;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
}

.mobile .certifications-container {
  padding-right: 0 !important;
  margin-left: 0 !important;
}

#table-container {
  position: relative;
}

::v-deep .modal {
  &.filter-menu {
    box-shadow: none;
  }
}

.filter-overlay {
  top: 12px; //match top padding
}

.v-btn.btn-link.row-link {
  font-weight: normal;
  color: pink;
}

::v-deep .v-data-table__mobile-table-row {
  padding: 0.75rem 0 1rem 0.5rem;
  display: inline-block;
  width: 100%;
  position: relative;

  border-bottom: thin solid rgba(0, 0, 0, 0.12);

  .v-data-table__mobile-row {
    &:not(:first-child) {
      padding-right: 4rem;
      height: auto;
      min-height: 1.75rem;

      & .v-data-table__mobile-row__cell {
        text-align: left;
        line-height: 1.5;
        height: 100%;
      }
    }

    &:first-child {
      height: 100%;
      position: absolute;
      top: 0;
      right: 1rem;
      padding: 0;
    }

    &:last-child {
      border-bottom: none !important;
    }
  }

  .fullName {
    font-size: 1rem;
    text-decoration: underline;
  }
}

.mobile-table {
  background-color: transparent;
  & ::v-deep table {
    background-color: $color-white;
  }
}

.table-top,
::v-deep .table-top {
  padding: 0 1.5rem;
}

.table-top {
  &__sort-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__sort {
    display: flex;
    align-items: center;
  }

  &__sort-by {
    padding-top: 0;

    &.v-input--is-focused ::v-deep .v-input__slot {
      border: 2px solid $color-primary;
      border-radius: 3px;
    }

    ::v-deep input {
      width: 0;
    }

    & ::v-deep .v-input__slot {
      border: 2px solid transparent;
      margin-bottom: 0;
      padding-left: 0.25rem;
    }

    & ::v-deep .v-select__slot {
      width: auto;
    }

    & ::v-deep .v-select__selection--comma {
      font-weight: bold;
      overflow: visible;
      color: $color-primary;
    }

    & ::v-deep .v-input__icon.v-input__icon--append .v-icon {
      color: $color-primary !important;
    }
  }
}

.table-controls {
  padding: 0 1.5rem;

  &__filter-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 0.75rem;
    margin: 0 -0.75rem;
  }

  &__filter-toggle-button {
    height: 2.25rem;
    width: 2.25rem;
    border: 2px solid transparent;

    &:focus {
      border: 2px solid $color-primary;
    }

    & .v-icon {
      margin-top: 4px;
    }

    &::before {
      content: none;
    }

    &.toggle-button--on {
      background-color: $color-primary;
      &:focus {
        box-shadow: 0 0 0 2px $ads-blue-3;
      }

      & .v-icon {
        color: $color-white !important;
      }
    }
  }

  &__filter-toggle-icon {
    color: $grey-darken1;
  }

  &__search-row {
    display: flex;
    align-items: center;
  }

  &__search {
    & ::v-deep .v-input__control {
      min-height: 54px;
    }

    & ::v-deep .v-text-field__slot {
      padding-left: 0.5rem;
    }
  }
}

.mobile {
  .table-controls__filter-row {
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin-top: 1rem;
    }
  }

  #table-container {
    padding-top: 0;
  }
}

.count-container {
  padding-top: 0 !important;

  & > * {
    height: 100%;
  }

  &--new {
    margin-right: -4px;
  }

  &--ready {
    margin-left: -4px;
  }
}

::v-deep .banner {
  padding-bottom: 16px !important;

  > div {
    padding-top: 16px !important;
  }
}

.searchInput {
  border-bottom: 1px solid #e4e4e6;
  color: #6d7079;
}
::v-deep .theme--light.v-chip {
  border: 0;
}
