
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-btn.chip.v-btn--outlined {
  border-color: $grey-darken1;
  border-width: 1px;
  background-color: white !important;
  color: $grey-darken1;
  height: 32px;
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0 12px;
}
.v-btn.chip.v-btn--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 2px;
}
a {
  text-decoration: none;
}

::v-deep .filter-card .theme--light.v-label {
  color: $grey-dark;
}

::v-deep .v-list-item__title {
  font-size: 16px;
}
.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
}

.filter-card {
  width: 18rem;
}

.modal {
  &.filter-menu {
    width: 100%;
    max-width: 100%;
    border-radius: 0 !important;
    top: 0 !important;
    left: 0 !important;
  }

  .filter-card {
    width: 100%;
    padding: 0 0.5rem;
  }

  .filter-options,
  .filter-options ::v-deep .v-input--radio-group__input {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 350px;

    > * {
      flex: 1 1 50px;
    }
  }
}
