
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.banner {
  margin-top: 0px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;
}

.divider {
  border-top: 0;
}

.flags {
  margin-top: 10px;

  & ::v-deep .v-list-item__content {
    color: $color-primary;
  }
}

.back-to-list--mobile {
  position: absolute;
  left: 20px;
}
.title {
  width: 90%;
  word-wrap: break-word;
  text-align: center;
}

.inline-alert__container ::v-deep .alert--element {
  margin-top: 20px;
}

::v-deep .v-tab {
  padding: 0 50px;
}

::v-deep .v-tab span {
  color: $color-primary;
}

.mobileFlags {
  margin: 20px auto;
}
