
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.error-message {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;

  &__text {
    margin-left: 0.4rem;
  }

  &__icon {
    align-self: flex-start;
  }
}
