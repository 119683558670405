
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.status-chip::v-deep {
  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
}

.theme--light .primary--border {
  border-color: $color-primary !important;
  border-width: thin;
  border-style: solid;
}
.ads-warning-orange {
  background-color: $ads-warning-orange !important;
}
.ads-tertiary-blue {
  background-color: $ads-tertiary-blue !important;
}
.ads-primary-blue-dark {
  background-color: $ads-primary-blue-dark !important;
}
.ads-success-green {
  background-color: $ads-success-green !important;
}
.ads-secondary-grey {
  background-color: $ads-secondary-grey !important;
}
