
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.detailsWrapper {
  padding: 1rem;
  margin-top: -2rem !important;
}

.doctorDetails {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
  padding-bottom: 20px;
}

.rowWrapper {
  font-weight: bold;
  font-size: 16px;
  color: $color-text-body;
  overflow-wrap: break-word;
  margin: 2rem;
  padding-top: 1rem !important;
}

::v-deep .v-input__slot fieldset {
  border: none;
}

::v-deep .col-md-4,
.col-12 {
  padding: 0 !important;
}
