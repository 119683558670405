
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-tab-navigation .v-slide-group__wrapper,
.v-tab-navigation .v-window__container {
  padding-left: 0 !important;
}

.v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.03em;
  &.v-tab--active {
    font-weight: bold;
  }
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-dark-60 !important;
}

.theme--light.v-tabs .v-tab.v-tab--active:focus::before {
  opacity: inherit;
  border: 4px solid $ads-navy !important;
}

.theme--dark.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-white !important;
}

.v-tab:before {
  background-color: inherit !important;
}

/* Custom 'outline' for OES applications, can refactor for other projects later */
.v-tab-navigation-vertical .v-tab.v-tab--active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0px 50px 50px 0px;
}
