
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pagination-nav {
  &__button {
    color: currentColor !important;

    &.theme--light.v-btn.v-btn--disabled {
      color: currentColor !important;

      & .v-icon {
        color: currentColor !important;
        opacity: 0.4;
      }
    }
  }
}
