
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.checkbox {
  flex-direction: column;

  &.error--text .checkbox__legend {
    color: $ads-error-red;
  }

  &__legend {
    margin-bottom: 0.5rem;
    animation: none !important;
  }
}
