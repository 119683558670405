
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.list-item-flag {
  .v-list-item__content {
    margin-top: -5px;
  }
}
.v-list-item__title {
  white-space: normal;
}
