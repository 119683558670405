
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.application-badge {
  text-transform: uppercase;
  border: 2px solid;
  padding: 0 3px;
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 1px;
  abbr {
    text-decoration: none;
    word-break: normal;
  }
}
