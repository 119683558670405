
          @import "@/scss/variables.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.radio-group {
  ::v-deep label.v-label {
    font-weight: normal;
    color: $ads-navy;
  }

  ::v-deep .v-input--radio-group__input legend {
    flex: 1 0 100%;
    margin-bottom: 0.5rem;
    height: auto;
  }

  ::v-deep .mdi-radiobox-blank {
    color: $color-grey-3;
  }
}
